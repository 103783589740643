import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function Owner({ dealers, setOwner, owner }) {
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      defaultValue={owner && { label: owner.name, id: owner._id }}
      onChange={(e, value) => setOwner(value.id)}
      options={dealers.map((dealer) => ({
        label: dealer.name,
        id: dealer._id,
      }))}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label={"Owner"} />}
    />
  );
}
